import React, { useEffect, useMemo, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  createDataExtract,
  getAllDistricts
} from "../../../../../store/superuser/actions";
import {
  SuperuserState,
  Data_Extract_Request,
} from "../../../../../store/superuser/types";
import {
  FileTypeOptions, TimePeriodOptions,
} from "../../../../../store/groups/types";
import Select from "react-select";
import { ReactSelectOption } from "../../../../../store/onboarding/types";
import { toastr } from "react-redux-toastr";
import DatePicker from "react-datepicker";

const defaultValue: Data_Extract_Request = {
  district_id: 0,
  file_type: "",
  time_period: "",
  year: "",
  start_month:'',
  end_month: '',
  receiver_email: ''
};

const DataExtractFrom = () => {
  const {
    districts
  } = useSelector<ApplicationState, SuperuserState>((s) => s.superuser);

  const dispatch = useDispatch();

  const [dataExtract, setDataExtract] = useState<Data_Extract_Request>(defaultValue);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  useEffect(() => {
    dispatch(getAllDistricts());
  }, []);

  const isDataExtractLoading = useSelector((s: ApplicationState) => s.superuser.isLoading.createDataExtract)

  const date = new Date();
  const currentYear = date.getFullYear();

  const possibleYears: ReactSelectOption<string>[] = [];
  for (let i = -5; i < 5; ++i) {
    possibleYears.push({
      value: (currentYear + i).toString(),
      label: (currentYear + i).toString(),
    });
  }

  const months: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const possibleMonths = useMemo(() => {
    let monthsArray: ReactSelectOption<any>[] = [];
    monthsArray = months.map((month, index) => {
      return {
        value: index + 1,
        label: month,
      }
    })
    return monthsArray;
  },[]);

  let districtsArray = useMemo(() => {
    let districtArr: ReactSelectOption<any>[] = [];
    districtArr = districts.map((district) => {
      return {
        value: district.id,
        label: district.name +' - '+ district.id,
      }
    })
    return districtArr;
  },[districts])

  const handleClose = () => {
    setDataExtract((prevState) => ({ ...prevState, ...defaultValue }));
    setStartDate(undefined);
    setEndDate(undefined);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (dataExtract.district_id 
      && dataExtract.file_type 
      && dataExtract.time_period 
      && dataExtract.year
      && dataExtract.start_month
      && dataExtract.end_month
      && dataExtract.receiver_email
    ) {
       dispatch<any>(createDataExtract(dataExtract));
    }
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="col-6 onboardingContainer">
        <h2 className="p-2 mb-2 purpleModalHeader rounded">Data Extract</h2>
          <Form id="dataExtractForm" onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label className="font-weight-bold" column={false}>
                District
              </Form.Label>
              <Select
                value={dataExtract.district_id ? districtsArray.find((d) => d.value === dataExtract.district_id): null}
                menuPlacement="auto"
                options={districtsArray}
                styles={{
                  menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                }}
                openMenuOnFocus
                onChange={(selectedOption:any) => 
                  setDataExtract((prevState) => ({ ...prevState, district_id: selectedOption.value }))}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <div className="row">
                <div className="col-6">
                  <Form.Label className="font-weight-bold" column={false}>
                    File Type
                  </Form.Label>
                  <Select
                    value={dataExtract.file_type ? FileTypeOptions.find((s) => s.value === dataExtract.file_type) : null}
                    menuPlacement="auto"
                    options={FileTypeOptions}
                    styles={{
                      menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                    }}
                    openMenuOnFocus
                    onChange={(selectedOption:any) => 
                      setDataExtract((prevState) => ({ ...prevState, file_type: selectedOption.value}))}
                  />
                </div>
                <div className="col-6">
                  <Form.Label className="font-weight-bold m-0"  column={false}>Time Period</Form.Label>
                      <Select
                        value={dataExtract.time_period ? TimePeriodOptions.find((s) => s.value === dataExtract.time_period) : null}
                        menuPlacement="auto"
                        options={TimePeriodOptions}
                        styles={{
                          menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                        }}
                        openMenuOnFocus
                        onChange={(selectedTPOption:any) => 
                          setDataExtract((prevState) => ({ ...prevState, time_period: selectedTPOption.value }))}
                      />
                </div>
              </div>
            </Form.Group>
             <Form.Group className="mb-3">
              <div className="row">
                <div className="col-4">
                  <Form.Label className="font-weight-bold" column={false}>
                    Year
                  </Form.Label>
                  <Select
                    value={dataExtract.year ? possibleYears.find((s) => s.value === dataExtract.year) : null}
                    menuPlacement="auto"
                    options={possibleYears}
                    styles={{
                      menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                    }}
                    openMenuOnFocus
                    onChange={(selectedYearOption:any) => 
                      setDataExtract((prevState) => ({ ...prevState, year: selectedYearOption.value }))}
                  />
                </div>
                <div className="col-4">
                  <Form.Label className="font-weight-bold" column={false}>
                    Start Month
                  </Form.Label>
                  <Select
                    placeholder="Select.."
                    value={dataExtract.start_month ? possibleMonths.find((s) => s.value === dataExtract.start_month) : null}
                    menuPlacement="auto"
                    options={possibleMonths}
                    styles={{
                      menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                    }}
                    openMenuOnFocus
                    onChange={(selectedYearOption:any) => 
                      setDataExtract((prevState) => ({ ...prevState, start_month: selectedYearOption.value }))}
                  />
                </div>
                <div className="col-4">
                  <Form.Label className="font-weight-bold" column={false}>
                    End Month
                  </Form.Label>
                  <Select
                     placeholder="Select..."
                    value={dataExtract.end_month ? possibleMonths.find((s) => s.value === dataExtract.end_month) : null}
                    menuPlacement="auto"
                    options={possibleMonths}
                    styles={{
                      menuPortal: (styles) => ({ ...styles, zIndex: 100 }),
                    }}
                    openMenuOnFocus
                    onChange={(selectedYearOption:any) => 
                      setDataExtract((prevState) => ({ ...prevState, end_month: selectedYearOption.value }))}
                  />
                </div>
              </div>
            </Form.Group>
            <Form.Group>
              <Form.Label className="font-weight-bold">
                Data Extract Receiver Email
              </Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter email"
                name="email"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => 
                  setDataExtract((prevState) => ({ ...prevState, receiver_email: e.target.value}))}
                value={dataExtract.receiver_email}
              />
            </Form.Group>
              <button
                className="blueBtnSm mr-2"
                type="submit"
                disabled={!dataExtract.district_id 
                  || (dataExtract.file_type == '') 
                  || (dataExtract.time_period == '') 
                  || (dataExtract.year == '')
                  || (dataExtract.start_month == '')
                  || (dataExtract.end_month == '')
                  || (dataExtract.receiver_email == '')
                  || isDataExtractLoading
                }
              >
                Send 
                {isDataExtractLoading && <Spinner animation="border" size="sm" />}
              </button>
              <button
                className="whiteBtnSm"
                type="reset"
                onClick={handleClose}
              >
                Reset
              </button>
          </Form>
      </div>
    </div>
  );
};

export default DataExtractFrom;
