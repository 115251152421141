import { createAction, createAsyncAction } from "typesafe-actions";
import { UserAssignmentRole, UserInfo } from "../auth/types";
import { Dispatch } from "redux";
import axios, { AxiosError } from "axios";
import {
  API_ADMIN_DISTRICT_LICENSES,
  API_ADMIN_DISTRICTS,
  API_ADMIN_USERS,
  API_ADMIN_RELEASE_NOTES,
  API_ADMIN_PUBLISH_RELEASE_NOTE,
  API_ADMIN_DATA_EXTRACT,
  API_ADMIN_MASTER_DATA_TYPES,
  API_ADMIN_MASTER_DATA_DISPLAY,
  API_ADMIN_MASTER_DATA_VARIATIONS,
  API_ADMIN_MASTER_DATA,
} from "../../constants";
import {
  District_Request,
  DistrictInfo,
  License,
  License_Request,
  Release_Notes,
  Data_Extract_Request,
  Master_Data_Variation,
} from "./types";
import { toastr } from "react-redux-toastr";

export const getAllDistrictsAction = createAsyncAction(
  "GET_ALL_DISTRICTS_REQUEST",
  "GET_ALL_DISTRICTS_SUCCESS",
  "GET_ALL_DISTRICTS_FAILURE"
)<void, Array<DistrictInfo>, Error>();

export const getAllDistricts = () => {
  return (dispatch: Dispatch) => {
    dispatch(getAllDistrictsAction.request());
    return axios
      .get(API_ADMIN_DISTRICTS())
      .then((res) => {
        const districts: DistrictInfo[] = res.data;
        dispatch(getAllDistrictsAction.success(districts));
      })
      .catch((err: AxiosError) => {
        dispatch(getAllDistrictsAction.failure(err));
      });
  };
};

export const getDistrictAction = createAsyncAction(
  "GET_DISTRICT_REQUEST",
  "GET_DISTRICT_SUCCESS",
  "GET_DISTRICT_FAILURE"
)<void, DistrictInfo, Error>();

export const getDistrict = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getDistrictAction.request());
    return axios
      .get(API_ADMIN_DISTRICTS(id))
      .then((res) => {
        const district: DistrictInfo = res.data;
        dispatch(getDistrictAction.success(district));
      })
      .catch((err: AxiosError) => {
        dispatch(getDistrictAction.failure(err));
      });
  };
};

export const createDistrictAction = createAsyncAction(
  "CREATE_DISTRICT_REQUEST",
  "CREATE_DISTRICT_SUCCESS",
  "CREATE_DISTRICT_FAILURE"
)<void, DistrictInfo, Error>();

export const createDistrict = (district: District_Request) => {
  return (dispatch: Dispatch) => {
    dispatch(createDistrictAction.request());
    return axios
      .post(API_ADMIN_DISTRICTS(), district)
      .then((res) => {
        const response: DistrictInfo = res.data;
        dispatch(createDistrictAction.success(response));
      })
      .catch((err: AxiosError) => {
        dispatch(createDistrictAction.failure(err));
      });
  };
};

export const updateDistrictAction = createAsyncAction(
  "UPDATE_DISTRICT_REQUEST",
  "UPDATE_DISTRICT_SUCCESS",
  "UPDATE_DISTRICT_FAILURE"
)<void, DistrictInfo, Error>();

export const updateDistrict = (request: District_Request) => {
  return (dispatch: Dispatch) => {
    dispatch(updateDistrictAction.request());
    return axios
      .put(API_ADMIN_DISTRICTS(request.id), request)
      .then((res) => {
        const response: DistrictInfo = res.data;
        dispatch(updateDistrictAction.success(response));
        return Promise.resolve(response);
      })
      .catch((err: AxiosError) => {
        dispatch(updateDistrictAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const deleteDistrictAction = createAsyncAction(
  "DELETE_DISTRICT_REQUEST",
  "DELETE_DISTRICT_SUCCESS",
  "DELETE_DISTRICT_FAILURE"
)<void, number, Error>();

export const deleteDistrict = (districtId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteDistrictAction.request());
    return axios
      .delete(API_ADMIN_DISTRICTS(districtId))
      .then(() => {
        dispatch(deleteDistrictAction.success(districtId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteDistrictAction.failure(err));
      });
  };
};

export const getDistrictLicensesAction = createAsyncAction(
  "GET_DISTRICT_LICENSES_REQUEST",
  "GET_DISTRICT_LICENSES_SUCCESS",
  "GET_DISTRICT_LICENSES_FAILURE"
)<void, { districtId: number; licenses: License[] }, Error>();

export const getDistrictLicenses = (districtId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getDistrictLicensesAction.request());
    return axios
      .get(API_ADMIN_DISTRICT_LICENSES(districtId))
      .then((res) => {
        const response: License[] = res.data;
        dispatch(
          getDistrictLicensesAction.success({
            districtId: districtId,
            licenses: response,
          })
        );
      })
      .catch((err: AxiosError) => {
        dispatch(getDistrictLicensesAction.failure(err));
      });
  };
};

export const createDistrictLicenseAction = createAsyncAction(
  "CREATE_DISTRICT_LICENSE_REQUEST",
  "CREATE_DISTRICT_LICENSE_SUCCESS",
  "CREATE_DISTRICT_LICENSE_FAILURE"
)<void, { districtId: number; license: License }, Error>();

export const createDistrictLicense = (
  districtId: number,
  license: License_Request
) => {
  return (dispatch: Dispatch) => {
    dispatch(createDistrictLicenseAction.request());
    return axios
      .post(API_ADMIN_DISTRICT_LICENSES(districtId), license)
      .then((res) => {
        const response: License = res.data;
        dispatch(
          createDistrictLicenseAction.success({
            districtId: districtId,
            license: response,
          })
        );
      })
      .catch((err: AxiosError) => {
        dispatch(createDistrictLicenseAction.failure(err));
      });
  };
};

export const updateDistrictLicenseAction = createAsyncAction(
  "UPDATE_DISTRICT_LICENSE_REQUEST",
  "UPDATE_DISTRICT_LICENSE_SUCCESS",
  "UPDATE_DISTRICT_LICENSE_FAILURE"
)<void, { districtId: number; license: License }, Error>();

export const updateDistrictLicense = (
  districtId: number,
  license: License_Request
) => {
  return (dispatch: Dispatch) => {
    dispatch(updateDistrictLicenseAction.request());
    return axios
      .put(API_ADMIN_DISTRICT_LICENSES(districtId, license.id), license)
      .then((res) => {
        const response: License = res.data;
        dispatch(
          updateDistrictLicenseAction.success({
            districtId: districtId,
            license: response,
          })
        );
      })
      .catch((err: AxiosError) => {
        dispatch(updateDistrictLicenseAction.failure(err));
      });
  };
};

export const deleteDistrictLicenseAction = createAsyncAction(
  "DELETE_DISTRICT_LICENSE_REQUEST",
  "DELETE_DISTRICT_LICENSE_SUCCESS",
  "DELETE_DISTRICT_LICENSE_FAILURE"
)<void, { districtId: number; licenseId: number }, Error>();

export const deleteDistrictLicense = (
  districtId: number,
  licenseId: number
) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteDistrictLicenseAction.request());
    return axios
      .delete(API_ADMIN_DISTRICT_LICENSES(districtId, licenseId))
      .then(() => {
        dispatch(
          deleteDistrictLicenseAction.success({
            districtId: districtId,
            licenseId: licenseId,
          })
        );
      })
      .catch((err: AxiosError) => {
        dispatch(deleteDistrictLicenseAction.failure(err));
      });
  };
};

export const getAllUsersAction = createAsyncAction(
  "GET_ALL_USERS_REQUEST",
  "GET_ALL_USERS_SUCCESS",
  "GET_ALL_USERS_FAILURE"
)<void, UserInfo[], Error>();

export const getAllUsers = () => {
  return (dispatch: Dispatch) => {
    dispatch(getAllUsersAction.request());
    return axios
      .get(API_ADMIN_USERS())
      .then((res) => {
        const users: UserInfo[] = res.data;
        dispatch(getAllUsersAction.success(users));
      })
      .catch((err: AxiosError) => {
        dispatch(getAllUsersAction.failure(err));
      });
  };
};

export const getUserAction = createAsyncAction(
  "GET_USER_REQUEST",
  "GET_USER_SUCCESS",
  "GET_USER_FAILURE"
)<void, UserInfo, Error>();

export const getUser = (id: number) => {
  return (dispatch: Dispatch) => {
    dispatch(getUserAction.request());
    return axios
      .get(API_ADMIN_USERS(id))
      .then((res) => {
        const user: UserInfo = res.data;
        dispatch(getUserAction.success(user));
      })
      .catch((err: AxiosError) => {
        dispatch(getUserAction.failure(err));
      });
  };
};

export const createUserAction = createAsyncAction(
  "CREATE_USER_REQUEST",
  "CREATE_USER_SUCCESS",
  "CREATE_USER_FAILURE"
)<void, UserInfo, Error>();

export const createUser = (
  request: Pick<UserInfo, "first_name" | "last_name" | "account_disabled"> & {
    district: number;
    role: UserAssignmentRole;
  }
) => {
  return (dispatch: Dispatch) => {
    dispatch(createUserAction.request());
    return axios
      .post(API_ADMIN_USERS(), { ...request, account_disabled: false })
      .then((res) => {
        const response: UserInfo = res.data;
        dispatch(createUserAction.success(response));
        return Promise.resolve(response);
      })
      .catch((err: AxiosError) => {
        dispatch(createUserAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const updateUserAction = createAsyncAction(
  "UPDATE_USER_REQUEST",
  "UPDATE_USER_SUCCESS",
  "UPDATE_USER_FAILURE"
)<void, UserInfo, Error>();

export const updateUser = ({
  id,
  ...request
}: Pick<UserInfo, "id" | "first_name" | "last_name" | "account_disabled"> & {
  district: number;
  role: UserAssignmentRole;
}) => {
  return (dispatch: Dispatch) => {
    dispatch(updateUserAction.request());
    return axios
      .put(API_ADMIN_USERS(id), request)
      .then((res) => {
        const response: UserInfo = res.data;
        dispatch(updateUserAction.success(response));
        return response;
      })
      .catch((err: AxiosError) => {
        dispatch(updateUserAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const deleteUserAction = createAsyncAction(
  "DELETE_USER_REQUEST",
  "DELETE_USER_SUCCESS",
  "DELETE_USER_FAILURE"
)<void, number, Error>();

export const deleteUser = (userId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(deleteUserAction.request());
    return axios
      .delete(API_ADMIN_USERS(userId))
      .then(() => {
        dispatch(deleteUserAction.success(userId));
      })
      .catch((err: AxiosError) => {
        dispatch(deleteUserAction.failure(err));
      });
  };
};

export const selectDistrict = createAction(
  "SELECT_DISTRICT"
)<DistrictInfo | null>();

export const selectDistrictLicense = createAction(
  "SELECT_DISTRICT_LICENSE"
)<License | null>();

export const setShowDistrictModal = createAction("SET_SHOW_DISTRICT_MODAL")<{
  show: boolean;
  district: DistrictInfo | null;
}>();
export const setShowDistrictLicenseModal = createAction(
  "SET_SHOW_DISTRICT_LICENSE_MODAL"
)<{
  show: boolean;
  license: License | null;
}>();

export const selectUser = createAction("SELECT_USER")<UserInfo | null>();

export const setShowUserModal = createAction("SET_SHOW_USER_MODAL")<{
  show: boolean;
  user: UserInfo | null;
  district?: number;
  role?: UserAssignmentRole;
  name?: string;
  onSuccess?: Function;
}>();

export const selectReleaseNote = createAction("SELECT_RELEASE_NOTE")<Release_Notes | null>();

export const setShowReleaseModal = createAction("SET_SHOW_RELEASE_MODAL")<{
  show: boolean;
  releaseNote: Release_Notes | null;
  onSuccess?: Function;
}>();

export const getAllReleaseNotesAction = createAsyncAction(
  "GET_ALL_RELEASE_NOTES_REQUEST",
  "GET_ALL_RELEASE_NOTES_SUCCESS",
  "GET_ALL_RELEASE_NOTES_FAILURE"
)<void, Release_Notes[], Error>();

export const getAllReleaseNotes = () => {
  return (dispatch: Dispatch) => {
    dispatch(getAllReleaseNotesAction.request());
    return axios
      .get(API_ADMIN_RELEASE_NOTES())
      .then((res) => {
        const releaseNotes: Release_Notes[] = res.data;
        dispatch(getAllReleaseNotesAction.success(releaseNotes));
      })
      .catch((err: AxiosError) => {
        dispatch(getAllReleaseNotesAction.failure(err));
      });
  };
};

// export const getReleaseNoteAction = createAsyncAction(
//   "GET_RELEASE_NOTE_REQUEST",
//   "GET_RELEASE_NOTE_SUCCESS",
//   "GET_RELEASE_NOTE_FAILURE"
// )<void, Release_Notes, Error>();

// export const getReleaseNote = (id: number) => {
//   return (dispatch: Dispatch) => {
//     dispatch(getReleaseNoteAction.request());
//     return axios
//       .get(API_ADMIN_RELEASE_NOTES(id))
//       .then((res) => {
//         const user: Release_Notes = res.data;
//         dispatch(getReleaseNoteAction.success(user));
//       })
//       .catch((err: AxiosError) => {
//         dispatch(getReleaseNoteAction.failure(err));
//       });
//   };
// };

export const createReleaseNoteAction = createAsyncAction(
  "CREATE_RELEASE_NOTE_REQUEST",
  "CREATE_RELEASE_NOTE_SUCCESS",
  "CREATE_RELEASE_NOTE_FAILURE"
)<void, Release_Notes, Error>();

export const createReleaseNote = (
  request: Pick<Release_Notes, "title" | "description">
) => {
  return (dispatch: Dispatch) => {
    dispatch(createReleaseNoteAction.request());
    return axios
      .post(API_ADMIN_RELEASE_NOTES(), { ...request })
      .then((res) => {
        const response: Release_Notes = res.data;
        dispatch(createReleaseNoteAction.success(response));
        return Promise.resolve(response);
      })
      .catch((err: AxiosError) => {
        dispatch(createReleaseNoteAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const updateReleaseNoteAction = createAsyncAction(
  "UPDATE_RELEASE_NOTE_REQUEST",
  "UPDATE_RELEASE_NOTE_SUCCESS",
  "UPDATE_RELEASE_NOTE_FAILURE"
)<void, Release_Notes, Error>();

export const updateReleaseNote = ({
  ...request
}: Pick<Release_Notes, "id" | "title" | "description" | "is_published">) => {
  return (dispatch: Dispatch) => {
    dispatch(updateReleaseNoteAction.request());
    return axios
      .put(API_ADMIN_RELEASE_NOTES() + (request.id ? `/${request.id}` : '') , request)
      .then((res) => {
        const response: Release_Notes = res.data;
        dispatch(updateReleaseNoteAction.success(response));
        return response;
      })
      .catch((err: AxiosError) => {
        dispatch(updateReleaseNoteAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const publishReleaseNoteAction = createAsyncAction(
  "PUBLISH_RELEASE_NOTE_REQUEST",
  "PUBLISH_RELEASE_NOTE_SUCCESS",
  "PUBLISH_RELEASE_NOTE_FAILURE"
)<void, number, Error>();

export const publishReleaseNote = (releaseNoteId: number) => {
  return (dispatch: Dispatch) => {
    dispatch(publishReleaseNoteAction.request());
    return axios
      .delete(API_ADMIN_PUBLISH_RELEASE_NOTE(releaseNoteId))
      .then(() => {
        dispatch(publishReleaseNoteAction.success(releaseNoteId));
      })
      .catch((err: AxiosError) => {
        dispatch(publishReleaseNoteAction.failure(err));
      });
  };
};

export const createDataExtractAction = createAsyncAction(
  "CREATE_DATA_EXTRACT_REQUEST",
  "CREATE_DATA_EXTRACT_SUCCESS",
  "CREATE_DATA_EXTRACT_FAILURE"
)<void, Data_Extract_Request, Error>();

export const createDataExtract = (dataExtract: Data_Extract_Request) => {
  return (dispatch: Dispatch) => {
    dispatch(createDataExtractAction.request());
    return axios
      .post(API_ADMIN_DATA_EXTRACT(), dataExtract)
      .then((res) => {
        const response: any = res.data;
        toastr.success('Success ', response.message)
        dispatch(createDataExtractAction.success(response))
      })
      .catch((err: AxiosError) => {
       dispatch(createDataExtractAction.failure(err))
      });
  };
};

export const masterDataTypesAction = createAsyncAction(
  "MASTER_DATA_TYPES_REQUEST",
  "MASTER_DATA_TYPES_SUCCESS",
  "MASTER_DATA_TYPES_FAILURE"
)<void, any, Error>();

export const getMasterDataTypes = () => {
  return (dispatch: Dispatch) => {
   // dispatch(masterDataTypesAction.request());
    return axios
      .get(API_ADMIN_MASTER_DATA_TYPES())
      .then((res) => {
        const response: any = res.data;
        return Promise.resolve(response);
        //dispatch(masterDataTypesAction.success(response));
      })
      .catch((err: AxiosError) => {
        //dispatch(masterDataTypesAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};

export const masterDataDisplayAction = createAsyncAction(
  "MASTER_DATA_DISPLAY_REQUEST",
  "MASTER_DATA_DISPLAY_SUCCESS",
  "MASTER_DATA_DISPLAY_FAILURE"
)<void, any, Error>();

export const getMasterDataDisplayNames = (types: string) => {
  return (dispatch: Dispatch) => {
    dispatch(masterDataDisplayAction.request());
    return axios
      .get(API_ADMIN_MASTER_DATA_DISPLAY(types))
      .then((res) => {
        const response: any = res.data;
        dispatch(masterDataDisplayAction.success(response));
        return Promise.resolve(response);
      })
      .catch((err: AxiosError) => {
        dispatch(masterDataDisplayAction.failure(err));
      });
  };
};

export const masterDataVariationAction = createAsyncAction(
  "MASTER_DATA_VARIATION_REQUEST",
  "MASTER_DATA_VARIATION_SUCCESS",
  "MASTER_DATA_VARIATION_FAILURE"
)<void, any, Error>();

export const getMasterDataVariations = (targetValue: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(masterDataVariationAction.request());
    return axios
      .get(API_ADMIN_MASTER_DATA_VARIATIONS(targetValue))
      .then((res) => {
        const response: any = res.data;
        dispatch(masterDataVariationAction.success(response));
        return Promise.resolve(response)
      })
      .catch((err: AxiosError) => {
        dispatch(masterDataVariationAction.failure(err));
      });
  };
};

export const createMasterDataVariationAction = createAsyncAction(
  "CREATE_MASTER_DATA_REQUEST",
  "CREATE_MASTER_DATA_SUCCESS",
  "CREATE_MASTER_DATA_FAILURE"
)<void, any, Error>();

export const createMasterDataVariation = (
  request: Partial<Master_Data_Variation>
) => {
  return (dispatch: Dispatch) => {
    //dispatch(createMasterDataVariationAction.request());
    return axios
      .post(API_ADMIN_MASTER_DATA(), request)
      .then((res) => {
        const response: any = res.data;
        dispatch(createMasterDataVariationAction.success(response));
        return Promise.resolve(response.message)
      })
      .catch((err: AxiosError) => {
       //dispatch(createMasterDataVariationAction.failure(err));
       return Promise.reject(err.message)
      });
  };
};

export const updateMasterDataVariationAction = createAsyncAction(
  "UPDATE_MASTER_DATA_REQUEST",
  "UPDATE_MASTER_DATA_SUCCESS",
  "UPDATE_MASTER_DATA_FAILURE"
)<void, any, Error>();

export const updateMasterDataVariation = (
  masterValueID: number,
  request: Partial<Master_Data_Variation>
) => {
  return (dispatch: Dispatch) => {
    //dispatch(updateMasterDataVariationAction.request());
    return axios
      .put(API_ADMIN_MASTER_DATA(masterValueID), request)
      .then((res) => {
        const response: any = res.data;
        dispatch(updateMasterDataVariationAction.success(response));
        return Promise.resolve(response.message)
      })
      .catch((err: AxiosError) => {
       //dispatch(updateMasterDataVariationAction.failure(err));
       return Promise.reject(err.message)
      });
  };
};

export const deleteMasterDataVariationAction = createAsyncAction(
  "DELETE_MASTER_DATA_REQUEST",
  "DELETE_MASTER_DATA_SUCCESS",
  "DELETE_MASTER_DATA_FAILURE"
)<void, any, Error>();

export const deleteMasterDataVariation = (id: number) => {
  return (dispatch: Dispatch) => {
    //dispatch(deleteMasterDataVariationAction.request());
    return axios
      .delete(API_ADMIN_MASTER_DATA(id))
      .then((res) => {
        //const response: any = res.data;
        dispatch(deleteMasterDataVariationAction.success(id));
        return Promise.resolve();
      })
      .catch((err: AxiosError) => {
        //dispatch(deleteMasterDataVariationAction.failure(err));
        return Promise.reject(err.message);
      });
  };
};